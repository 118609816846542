export var equals = 'fantasy-land/equals';
export var lte = 'fantasy-land/lte';
export var compose = 'fantasy-land/compose';
export var id = 'fantasy-land/id';
export var concat = 'fantasy-land/concat';
export var empty = 'fantasy-land/empty';
export var map = 'fantasy-land/map';
export var contramap = 'fantasy-land/contramap';
export var ap = 'fantasy-land/ap';
export var of = 'fantasy-land/of';
export var alt = 'fantasy-land/alt';
export var zero = 'fantasy-land/zero';
export var reduce = 'fantasy-land/reduce';
export var traverse = 'fantasy-land/traverse';
export var chain = 'fantasy-land/chain';
export var chainRec = 'fantasy-land/chainRec';
export var extend = 'fantasy-land/extend';
export var extract = 'fantasy-land/extract';
export var bimap = 'fantasy-land/bimap';
export var promap = 'fantasy-land/promap';