function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { equals, pathSatisfies } from 'ramda';
import isString from '../isString';
import isNumber from '../isNumber';
import isFunction from '../isFunction';
import { isSameType } from './util';
import * as fl from './mapping';
export var functorTrait = _defineProperty({}, fl.map, function (fn) {
  return this.constructor[fl.of](fn(this.value));
});
export var applyTrait = _defineProperty({}, fl.ap, function (applyWithFn) {
  var _this = this;

  return applyWithFn.map(function (fn) {
    return fn(_this.value);
  });
});
export var setoidTrait = _defineProperty({}, fl.equals, function (setoid) {
  return isSameType(this, setoid) && equals(this.value, setoid.value);
});
export var semigroupTrait = _defineProperty({}, fl.concat, function (semigroup) {
  var concatenatedValue = this.value;

  if (isString(this.value) || isNumber(this.value)) {
    concatenatedValue = this.value + semigroup.value;
  } else if (pathSatisfies(isFunction, ['value', fl.concat], this)) {
    concatenatedValue = this.value[fl.concat](semigroup.value);
  } else if (pathSatisfies(isFunction, ['value', 'concat'], this)) {
    concatenatedValue = this.value.concat(semigroup.value);
  }

  return this.constructor[fl.of](concatenatedValue);
});
export var chainTrait = _defineProperty({}, fl.chain, function (fn) {
  var newChain = fn(this.value);
  return isSameType(this, newChain) ? newChain : this;
});
export var ordTrait = _defineProperty({}, fl.lte, function (ord) {
  return isSameType(this, ord) && (this.value < ord.value || this[fl.equals](ord));
});